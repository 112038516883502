// polyfill for cypress tests...
import 'whatwg-fetch';
import './style.scss';
import { configStore } from '@vfde-fe/sails_core';
import connectedFlyout from 'Container/Flyout/index';
import { FLYOUT_CLASSNAME } from 'Container/Flyout/constant';

const store = configStore({});
const app = document.getElementsByClassName(FLYOUT_CLASSNAME)[0] as HTMLElement;

if (store && app) {
    connectedFlyout();
}
