import {
    all,
    call,
    select,
    take,
    takeLatest,
} from 'redux-saga/effects';
import { trackIt } from '@vfde-fe/sails_core';
import {
    DEFAULT_TRACKING_TOOL_NAME,
    SET_ACTIVE_PAGE,
    SET_CONTACT_INFO,
} from './constant';
import {
    selectActivePage,
    selectAllPages,
    selectPreviousPage,
} from './selector';
import { txtNormalize } from './helper/tracking';
import beacon from '@vfde-fe/sails-beacon';
import {
    END,
    eventChannel,
} from 'redux-saga';

/**
 * Export generator function
 */
export default function* flyoutSaga () {
    yield takeLatest(SET_CONTACT_INFO, setContactInfoSaga);
    yield takeLatest(SET_ACTIVE_PAGE, setTrackingToolSaga);
    yield takeLatest(SET_ACTIVE_PAGE, handleSetActivePageSaga);
}

/**
 * setTrackingToolSaga
 */
export function* setTrackingToolSaga (action) {
    if (!action.activePage) {
        return;
    }

    const [ previousPage, allPages ] = yield all([
        select(selectPreviousPage()),
        select(selectAllPages()),
    ]);

    let tool = allPages.find(page => page.id === action.activePage)?.trackingName || DEFAULT_TRACKING_TOOL_NAME;

    // const variant = (previousPage === allPages[allPages.length - 1].id) ? 'reactive' :  'proactive';
    const variant = 'reactive';

    tool = txtNormalize(tool);

    const tools = [];
    allPages.forEach(element => {
        if (element.trackingName) {
            tools.push(element.trackingName);
        }
    });

    trackIt('flyout', {
        action: 'click',
        tool,
        variant,
        tools,
    });

}
/**
 * Runs matelso can and publishes to navigation beacon after contact info has been set
 */
export function* setContactInfoSaga (action) {
    const channel = yield call(waitForMatelso);

    // Publish contact information to global navigation contact section
    yield call(
        [beacon, 'publish'],
        'sails:flyout',
        {
            type: 'SET_CONTACT_INFO',
            payload: {
                simplicityContactInfo: {
                    ...action.simplicityContactInfo,
                },
            },
        },
    );

    // Matelso scan
    try {
        yield take(channel);
        yield call([window.mtls, 'scan']);
    } catch (e) {
        console.log(e.message);
        channel.close();
    }
}

/**
 * Handle active page
 */
export function* handleSetActivePageSaga () {
    const activePage = yield select(selectActivePage());

    // Publish opening state to global navigation contact section
    yield call(
        [beacon, 'publish'],
        'sails:flyout',
        {
            type: 'TOGGLE_FLYOUT_SUCCESS',
            payload: {
                activePage,
            },
        },
    );
}

/**
 * Watch for matelso object via interval
 * @param triesLeft
 */
export function waitForMatelso (triesLeft = 15) {
    return eventChannel(emit => {
        const interval = setInterval(() => {
            if ('undefined' !== typeof window.mtls) {
                emit(true);
                emit(END);
            } else if (triesLeft <= 1) {
                emit(new Error('No matelso present.'));
            }
            triesLeft--;
        }, 200);

        return () => {
            clearInterval(interval);
        };
    });
}
