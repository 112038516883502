// tslint:disable:completed-docs
import { NAMESPACE } from 'Constant';

/**
 * Actions
 */
export const SET_CONTACT_INFO = `${NAMESPACE}/SET_CONTACT_INFO`;
export const SET_ACTIVE_PAGE = `${NAMESPACE}/SET_ACTIVE_PAGE`;
export const ADD_PAGE = `${NAMESPACE}/ADD_PAGE`;

/**
 * IDs
 */
export const ID_DYNAMIC_BUTTON_LIST = 'cf-dynamic-button-list';

/**
 * Attributes
 */
export const ATTRIBUTE_BUTTON_LABEL = 'data-button-label';
export const ATTRIBUTE_BUTTON_ICON = 'data-button-icon';
export const ATTRIBUTE_SHOW_CALL_BUTTON = 'data-show-default-button';
export const ATTRIBUTE_SHOW_BUSSINESS_HOURS = 'data-show-default-hours';

/**
 * Class names
 */
export const FLYOUT_CLASSNAME = 'contact-flyout';
export const FLYOUT_VISIBLE_CLASSNAME = `${FLYOUT_CLASSNAME}--visible`;
export const FLYOUT_OPEN_CLASSNAME = `${FLYOUT_CLASSNAME}--open`;
export const FLYOUT_FEW_ITEMS_CLASSNAME = `${FLYOUT_CLASSNAME}--few-items`;
export const FLYOUT_HIDDEN_NOTCH_CLASSNAME = `${FLYOUT_CLASSNAME}--hidden-notch`;
export const PANEL_CLASSNAME = `${FLYOUT_CLASSNAME}__container`;
export const PANEL_ACTIVE_CLASSNAME = `${PANEL_CLASSNAME}--active`;
export const TRIGGER_BUTTON_CLASSNAME = `${FLYOUT_CLASSNAME}__button`;
export const ALL_OPTIONS_BUTTON_CLASSNAME = `${FLYOUT_CLASSNAME}__button-all-contact-options`;
export const CLOSE_BUTTON_CLASSNAME = `${FLYOUT_CLASSNAME}__close-button`;
export const CONTENT_CONTAINER_CLASSNAME = `${FLYOUT_CLASSNAME}__content`;
export const CONTENT_MOBILE_CLASSNAME = `${FLYOUT_CLASSNAME}__content-mobile`;
export const CONTENT_DESKTOP_CLASSNAME = `${FLYOUT_CLASSNAME}__content-desktop`;
export const CONTENT_DEFAULT_CLASSNAME = `${FLYOUT_CLASSNAME}__content-default`;
export const DRAWER_CLASSNAME = `${FLYOUT_CLASSNAME}__drawer`;
export const OPENING_HOURS_CLASSNAME = `${FLYOUT_CLASSNAME}__opening-hours`;
export const BUTTON_CONTAINER_HOTLINE_PHONE_CLASSNAME = `${FLYOUT_CLASSNAME}__hotline-phone`;

/**
 * Tracking
 */
export const DATA_FIELD_TRACKING = 'data-tracking-name';
export const DEFAULT_TRACKING_TOOL_NAME = 'flyout';

/**
 * Layout
 */
export const MAX_NUMBER_FEW_ITEMS_MODE = 3;
