import { SimplicityFlyoutConfigItem } from 'Container/Flyout/interface';

/**
 * Reads the default contant info from a variable that lives in
 * the window['VF'].SAILS.flyout namespace
 * This variable is set by simplicity (hotlines-templates-controller.js)
 */
export const getContactInfoFromWindow = (): SimplicityFlyoutConfigItem['content'] => {
    return window['VF']?.SAILS?.flyout?.simplicityContactInfo;
};
