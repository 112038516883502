import {
    ALL_OPTIONS_BUTTON_CLASSNAME,
    FLYOUT_VISIBLE_CLASSNAME,
    ATTRIBUTE_SHOW_BUSSINESS_HOURS,
    ATTRIBUTE_SHOW_CALL_BUTTON,
    CLOSE_BUTTON_CLASSNAME,
    CONTENT_CONTAINER_CLASSNAME,
    CONTENT_DEFAULT_CLASSNAME,
    CONTENT_DESKTOP_CLASSNAME,
    CONTENT_MOBILE_CLASSNAME,
    FLYOUT_OPEN_CLASSNAME,
    DATA_FIELD_TRACKING,
    DRAWER_CLASSNAME,
    FLYOUT_FEW_ITEMS_CLASSNAME,
    MAX_NUMBER_FEW_ITEMS_MODE,
    PANEL_ACTIVE_CLASSNAME,
    PANEL_CLASSNAME,
    TRIGGER_BUTTON_CLASSNAME,
    FLYOUT_HIDDEN_NOTCH_CLASSNAME,
} from 'Container/Flyout/constant';
import {
    DOMEvent,
    IActions,
    SimplicityFlyoutConfigItem,
} from 'Container/Flyout/interface';
import mountContactButton from 'Container/Flyout/components/BusinessHoursAndContactButton';
import { addEditorialTracking } from 'Container/Flyout/helper/tracking';
import { trackIt } from '@vfde-fe/sails_core';
import { isKeyboardTriggeredClickEvent } from '@vfde-fe/utils';
import { getKeyboardFocusableElements } from 'Helper/domHelper';

const mountFlyout = (containerElement: HTMLElement, actions: IActions): Flyout => {
    return new Flyout(containerElement, {
        onTriggerClick: (event, panel) => {
            actions.setActivePage(panel.id, isKeyboardTriggeredClickEvent(event));
        },
        onCloseButtonClick: (event, panel) => {
            actions.setActivePage(null);
        },
        onOutsideClick: (event) => {
            actions.setActivePage(null);
        },
        onFlyoutReveal: (event, flyout: Flyout) => {
            // Add panels to tracking
            for (const panel of flyout.panels) {
                // add the page to the store
                actions.addPage(panel.id, panel.getAttribute(DATA_FIELD_TRACKING) || undefined);
                addEditorialTracking(panel, trackIt);
            }
        },
    });
};

interface FlyoutBusinessLogic {
    /**
     * Click on panel trigger button
     * @param event
     * @param panel
     */
    onTriggerClick (event: MouseEvent, panel: HTMLElement): void;

    /**
     * Click on panel close button
     * @param event
     * @param panel
     */
    onCloseButtonClick (event: MouseEvent, panel: HTMLElement): void;

    /**
     * Click outside flyout
     * @param event
     */
    onOutsideClick (event: MouseEvent): void;

    /**
     * When flyout app is first shown
     * @param event
     * @param flyout
     */
    onFlyoutReveal? (event: MouseEvent, flyout: Flyout): void;

    /**
     * When panel opens
     * @param event
     * @param flyout
     */
    onFlyoutOpen? (event: MouseEvent, flyout: Flyout): void;

    /**
     * When panel closes
     * @param event
     * @param flyout
     */
    onFlyoutClose? (event: MouseEvent, flyout: Flyout): void;
}

/**
 * Flyout component
 */
class Flyout {
    private _containerElement: HTMLElement;
    private _panels: HTMLCollectionOf<HTMLElement>;
    private _drawer: HTMLElement;
    private _transitionStart: number;

    constructor (containerElement: HTMLElement, businessLogic: FlyoutBusinessLogic) {
        this._containerElement = containerElement;
        this._panels = this._containerElement.getElementsByClassName(PANEL_CLASSNAME) as HTMLCollectionOf<HTMLElement>;
        this._drawer = this._containerElement.getElementsByClassName(DRAWER_CLASSNAME)[0] as HTMLElement;

        if (window.globalPageOptions?.flyout?.hiddenNotch) {
            this._containerElement.classList.add(FLYOUT_HIDDEN_NOTCH_CLASSNAME);
        }

        if (this._panels.length <= MAX_NUMBER_FEW_ITEMS_MODE) {
            this._containerElement.classList.add(FLYOUT_FEW_ITEMS_CLASSNAME);
        }

        this.initPanels();

        this.initEvents(businessLogic);
    }

    /**
     * Get panels
     */
    get panels (): HTMLCollectionOf<HTMLElement> {
        return this._panels;
    }

    /**
     * Init panels
     */
    public initPanels (): void {
        for (const panel of this._panels) {
            // add desktop class to content panel if mobile content is present
            if (panel.getElementsByClassName(CONTENT_MOBILE_CLASSNAME).length > 0) {
                panel.getElementsByClassName(CONTENT_DEFAULT_CLASSNAME)[0].classList.add(CONTENT_DESKTOP_CLASSNAME);
            }
        }
    }

    /**
     * Open a panel
     * @param id
     * @param focus
     * @param hideNotch
     */
    public openPanel (id: string, focus = false, hideNotch = false): void {
        !this.isOpen() && this.openFlyout(id, focus);

        // Close all open panels
        this.closePanels();

        // open triggered panel
        const panel = document.getElementById(id);
        panel.classList.add(PANEL_ACTIVE_CLASSNAME);
        panel.getElementsByClassName(TRIGGER_BUTTON_CLASSNAME)[0].setAttribute('disabled', 'true');
        (<HTMLButtonElement>panel.getElementsByClassName(TRIGGER_BUTTON_CLASSNAME)[0]).blur();

        if (focus) {
            const focusableElements = getKeyboardFocusableElements(panel.getElementsByClassName(CONTENT_CONTAINER_CLASSNAME)[0] as HTMLElement);
            focusableElements[0].focus();
        }
    }

    /**
     * Closes a panel
     */
    public closePanels (): void {
        for (const opened of this._containerElement.getElementsByClassName(PANEL_ACTIVE_CLASSNAME)) {
            opened.classList.remove(PANEL_ACTIVE_CLASSNAME);
            opened.getElementsByClassName(TRIGGER_BUTTON_CLASSNAME)[0].removeAttribute('disabled');
        }
    }

    /**
     * Returns true if flyout drawer is open
     */
    public isOpen (): boolean {
        return this._containerElement.classList.contains(FLYOUT_OPEN_CLASSNAME);
    }

    /**
     * Open drawer
     * @param id
     * @param focus
     */
    public openFlyout (id: string, focus): void {
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';

        window.scrollTo(0, 0);

        this._containerElement.classList.add(FLYOUT_OPEN_CLASSNAME);
    }

    /**
     * Close flyout (nothing open anymore)
     */
    public closeFlyout (): void {
        this._containerElement.classList.remove(FLYOUT_OPEN_CLASSNAME);
    }

    /**
     * Flyout is invisible and only revealed after calling this method
     */
    public reveal (): void {
        this._containerElement.classList.add(FLYOUT_VISIBLE_CLASSNAME);
    }

    /**
     * Add contact button
     * @param simplicityContactInfo
     * @TODO this is not very generic right now, move to index.ts?
     */
    public addDynamicContent (simplicityContactInfo: SimplicityFlyoutConfigItem['content']): void {
        this._containerElement.querySelectorAll<HTMLElement>(`[${ATTRIBUTE_SHOW_BUSSINESS_HOURS}=true], [${ATTRIBUTE_SHOW_CALL_BUTTON}=true]`).forEach(customSection => {
            mountContactButton(customSection, simplicityContactInfo);
        });
    }

    private initEvents (businessLogic): void {
        // Fire open/close events after transition end
        this._drawer.addEventListener('transitionstart', (e: DOMEvent<TransitionEvent, HTMLElement>) => {
            const { currentTarget, target, propertyName } = e;

            if (currentTarget === target) {
                const transformX = propertyName === 'transform' && Math.round(new DOMMatrixReadOnly(window.getComputedStyle(target).getPropertyValue('transform')).e);
                this._transitionStart = transformX;
            }
        });

        this._drawer.addEventListener('transitionend', (e: DOMEvent<TransitionEvent, HTMLElement>) => {
            const { currentTarget, target, propertyName } = e;

            if (currentTarget !== target) {
                return;
            }

            const transformX = propertyName === 'transform' && Math.round(new DOMMatrixReadOnly(window.getComputedStyle(target).getPropertyValue('transform')).e);
            const triggerWidth = Array.from(this._panels).map(panel => parseInt(getComputedStyle(panel.getElementsByClassName(TRIGGER_BUTTON_CLASSNAME)[0]).width, 10)).filter(scrollWidth => !!scrollWidth)[0];

            if (transformX === -triggerWidth && this._transitionStart >= -triggerWidth) {
                this._containerElement.dispatchEvent(new CustomEvent('flyout:revealed'));

                return;
            }

            if (transformX === -triggerWidth) {
                this._containerElement.dispatchEvent(new CustomEvent('flyout:closed'));

                return;
            }

            if (transformX === -this._drawer.scrollWidth) {
                this._containerElement.dispatchEvent(new CustomEvent('flyout:opened'));

                return;
            }
        });

        // Trigger and close buttons
        for (const panel of this._panels) {
            // Trigger
            const trigger = panel.getElementsByClassName(TRIGGER_BUTTON_CLASSNAME)[0];
            trigger.addEventListener('click', (e: MouseEvent) => {
                this.openPanel(panel.id, isKeyboardTriggeredClickEvent(e));
                businessLogic.onTriggerClick(e, panel);
            });

            // close button
            panel.getElementsByClassName(CLOSE_BUTTON_CLASSNAME)[0].addEventListener('click', (e: MouseEvent) => {
                this.closeFlyout();
                businessLogic.onCloseButtonClick(e, panel);
            });

            // "Back to all options panel"
            const allOptionsButton = panel.getElementsByClassName(ALL_OPTIONS_BUTTON_CLASSNAME)[0];
            allOptionsButton?.addEventListener('click', (e: MouseEvent) => {
                const panel = [...this._panels].pop();
                this.openPanel(panel.id, isKeyboardTriggeredClickEvent(e));
                businessLogic.onTriggerClick(e, panel);
            });
        }

        // Close on outside click
        document.addEventListener('click', (event) => {
            if ((this._containerElement.contains(event.target as HTMLElement) && this._containerElement !== event.target) || !this.isOpen()) {
                return;
            }

            this.closeFlyout();
            businessLogic.onOutsideClick(event);
        });

        // Contain focus within flyout while it's open
        this._containerElement.addEventListener('focusout', (e: DOMEvent<FocusEvent, HTMLElement>) => {
            const { currentTarget, relatedTarget, target } = e;

            if (!this.isOpen()) {
                return;
            }

            if (e.relatedTarget && !(<HTMLElement>currentTarget).contains(relatedTarget as HTMLElement)) {
                const shiftKeyPressed = target.compareDocumentPosition(relatedTarget as Node) === 2;
                getKeyboardFocusableElements(currentTarget as HTMLElement)[shiftKeyPressed ? 'pop' : 'shift']().focus();
            }
        });

        // On revealing the flyout
        this._containerElement.addEventListener('flyout:revealed', (event: CustomEvent) => {
            businessLogic.onFlyoutReveal && businessLogic.onFlyoutReveal(event, this);
        });

        // After opening
        this._containerElement.addEventListener('flyout:opened', (event: CustomEvent) => {
            businessLogic.onFlyoutOpen && businessLogic.onFlyoutOpen(event, this);
        });

        // After closing
        this._containerElement.addEventListener('flyout:closed', (event: CustomEvent) => {
            document.body.style.position = '';
            document.body.style.width = '';
            this.closePanels();
            window.scrollTo(0, 0);
            businessLogic.onFlyoutClose && businessLogic.onFlyoutClose(event, this);
        });
    }
}

export default mountFlyout;
