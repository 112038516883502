import produce from 'immer';
import {
    ADD_PAGE,
    SET_CONTACT_INFO,
    SET_ACTIVE_PAGE,
} from './constant';
import {
    IFlyoutState,
} from 'Container/Flyout/interface';
import { AnyAction } from 'redux';

/**
 * initialState
 */
export const initialState: IFlyoutState = {
    activePage: undefined,
    previousPage: undefined,
    allPages: [],
    simplicityContactInfo: undefined,
    focus: false,
    external: false,
};

/**
 * Reducer
 */
export default (state: IFlyoutState = initialState, action: AnyAction) =>
    produce(state, (draft: IFlyoutState) => {
        switch (action.type) {
            case SET_CONTACT_INFO:
                draft.simplicityContactInfo = action.simplicityContactInfo;
            break;
            case SET_ACTIVE_PAGE:
                draft.previousPage = state.activePage;
                draft.activePage = action.activePage;
                draft.focus = action.focus;
                draft.external = action.external;
            break;
            case ADD_PAGE:
                const { id, trackingName } = action;
                draft.allPages.push({
                    id,
                    trackingName,
                });
            break;
        }
    });
