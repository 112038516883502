import { ATTRIBUTE_SHOW_BUSSINESS_HOURS, ATTRIBUTE_SHOW_CALL_BUTTON, BUTTON_CONTAINER_HOTLINE_PHONE_CLASSNAME, OPENING_HOURS_CLASSNAME } from '../constant';
import { SimplicityFlyoutConfigItem } from 'Container/Flyout/interface';
import { createJustText } from '@vfde-brix/just-text';
import { createButtonComponent } from '@vfde-brix/button';

export default (containerToAdd: HTMLElement, simplicityContactInfo: SimplicityFlyoutConfigItem['content']) => {
    if (containerToAdd.getAttribute(ATTRIBUTE_SHOW_BUSSINESS_HOURS) === 'true') {
        const textContainer = document.createElement('p');

        createJustText(textContainer, {
            tag: 'p',
            content: simplicityContactInfo.mobileCallTimes,
            className: `brix-text brix-spacing-100 ${OPENING_HOURS_CLASSNAME}`,
            business: {},
        });

        containerToAdd.append(textContainer);
    }

    if (containerToAdd.getAttribute(ATTRIBUTE_SHOW_CALL_BUTTON) === 'true') {
        const buttonContainer = document.createElement('div');
        buttonContainer.classList.add(BUTTON_CONTAINER_HOTLINE_PHONE_CLASSNAME);

        const { mobileCallNumber } = simplicityContactInfo;
        const mobileCallNumberForTelHref = mobileCallNumber
            .replace(/\s+/g, ''); // remove spaces
            // .replace(/^0+/, '+49'); // replacing of leading zeros with +49 seems to be done by some other script (maybe Matelso?)

        createButtonComponent(buttonContainer, {
            optWidthVariation: 'fullwidth',
            optShape: 'rectangle',
            optColor: 'strong',
            size: 'large',
            business: {},
            href: `tel:${mobileCallNumberForTelHref}`,
            stdTrackingName: JSON.stringify([
                'hotline cta',
                {
                    number: mobileCallNumberForTelHref,
                },
            ]),
            content: mobileCallNumber.trim(),
        });

        containerToAdd.append(buttonContainer);
    }
};
