import {
    DATA_FIELD_TRACKING,
} from '../constant';
import { DOMEvent } from 'Container/Flyout/interface';

/**
 * add editorial tracking
 */
export const addEditorialTracking = (containerDiv: Element, trackingFunction: (type: string, trackingData: any) => void) => {
    const trackingElements = containerDiv.querySelectorAll(`[${DATA_FIELD_TRACKING}]:not([${DATA_FIELD_TRACKING}=''])`);

    for (const trackingElement of trackingElements) {
        trackingElement.addEventListener('click', (e: DOMEvent<MouseEvent, HTMLAnchorElement>) => {
            e.preventDefault();
            const href = e.currentTarget.href;
            const trackingString = trackingElement.getAttribute(DATA_FIELD_TRACKING);
            const formatToJsonString = txtNormalize(trackingString.replace(/'/gi, '"'));
            let trackingData: any[];

            try {
                trackingData = JSON.parse(formatToJsonString);
            } catch {
                trackingData = null;
            }

            if (trackingData) {
                if (trackingData[1] && href && href.indexOf('tel:') !== -1) {
                    // replace number in tracking json in case this is not caught by matelso
                    trackingData[1].number = href.replace('tel:', '');
                }

                trackingFunction(trackingData[0], trackingData[1]);
            }

            window.location.assign(href);
        });
    }
};

/**
 * txtNormalize
 * that should be moved somewhere else
 */
export const txtNormalize = (txtInput: string): string => {
    txtInput = txtInput
        .replace(/ä/g, 'ae')
        .replace(/Ä/g, 'Ae')
        .replace(/ö/g, 'oe')
        .replace(/Ö/g, 'Oe')
        .replace(/ü/g, 'ue')
        .replace(/Ü/g, 'Ue')
        .replace(/ß/g, 'ss');

    return txtInput;
};
