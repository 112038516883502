import {
    ADD_PAGE,
    DEFAULT_TRACKING_TOOL_NAME,
    SET_CONTACT_INFO,
    SET_ACTIVE_PAGE,
} from './constant';

import {
    AddPageTrackingAction,
    SetContactInfoAction,
    SetActivePageAction,
    SimplicityFlyoutConfigItem,
} from 'Container/Flyout/interface';

/**
 * Set contact info
 */
export const setContactInfo = (simplicityContactInfo: SimplicityFlyoutConfigItem['content']): SetContactInfoAction => ({
    type: SET_CONTACT_INFO,
    simplicityContactInfo,
});
/**
 * setPageActive
 */
export const setActivePage = (activePage: string, focus = false, external = false): SetActivePageAction => ({
    type: SET_ACTIVE_PAGE,
    activePage,
    focus,
    external,
});

/**
 * addPage
 */
export const addPage = (id: string, trackingName: string = DEFAULT_TRACKING_TOOL_NAME): AddPageTrackingAction => ({
    type: ADD_PAGE,
    id,
    trackingName,
});
