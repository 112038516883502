/**
 * Gets keyboard-focusable elements within a specified element
 * @param {HTMLElement} [element=document.body] element
 * @returns {Array}
 */
export const getKeyboardFocusableElements = (element: HTMLElement = document.body): Array<HTMLElement> => {
    return [
        ...element.querySelectorAll(
            'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
        ),
    ].filter(
        (el: HTMLElement) => !(el.hasAttribute('tabindex') && el.tabIndex === -1)
            && !el.hasAttribute('disabled')
            && !el.getAttribute('aria-hidden')
            && isVisible(el),
    ) as HTMLElement[];
};

/**
 * Checks if element is visible
 * @param element
 */
export const isVisible = (element: HTMLElement): boolean => {
    return !!element.offsetWidth || !!element.offsetHeight || !!element.getClientRects().length;
};
