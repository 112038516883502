import {
    ATTRIBUTE_BUTTON_ICON,
    ATTRIBUTE_BUTTON_LABEL,
} from '../constant';
import {
    ButtonTiles,
    createButtonTilesComponent,
    IButtonTilesItem,
} from '@vfde-brix/button-tiles';

/**
 * Button tiles container ID
 */
export const ALL_OPTIONS_BUTTON_TILE_ID = 'all-options';

export default (containerElement: HTMLElement, panels: Array<Element>, clickHandler): ButtonTiles => {
    const items = [];

    panels.forEach((container, index) => {
        if (index > panels.length - 2) {
            return;
        }

        const id = container.getAttribute('id');
        const label = decodeURI(container.getAttribute(ATTRIBUTE_BUTTON_LABEL));
        const iconURl = decodeURI(container.getAttribute(ATTRIBUTE_BUTTON_ICON));

        const item: IButtonTilesItem = {
            tagName: 'button',
            infoId: id,
            imgIconSrc: iconURl,
            stdLabel: label,
        };
        items.push(item);
    });

    return createButtonTilesComponent(containerElement, {
        infoUid: ALL_OPTIONS_BUTTON_TILE_ID,
        items,
        optBehavior: 'stacked',
        business: {
            onClick: clickHandler,
        },
    });
};
