import {
    connect,
    injectReducer,
    injectSaga,
} from '@vfde-fe/sails_core';
import { createStructuredSelector } from 'reselect';
import { NAMESPACE } from 'Constant';
import {
    FLYOUT_CLASSNAME,
    ID_DYNAMIC_BUTTON_LIST,
} from './constant';
import {
    addPage,
    setContactInfo,
    setActivePage,
} from './actions';
import flyoutReducer from './reducer';
import {
    selectActivePage,
    selectSimplicityContactInfo,
    selectExternal,
    selectFocus,
    selectIsInitialized,
} from './selector';
import flyoutSaga from './saga';
import mountAllOptionsPage from './components/AllOptionsPage';
import { RootStateOrAny } from 'react-redux';
import {
    BeaconSetActivePage,
    IActions,
    SimplicityFlyoutConfigItem,
    StateProps,
} from 'Container/Flyout/interface';
import mountFlyout from 'Container/Flyout/components/Flyout';
import { isKeyboardTriggeredClickEvent } from '@vfde-fe/utils';
import beacon, { BeaconAction } from '@vfde-fe/sails-beacon';
import { getContactInfoFromWindow } from './helper/getContactInfo';

const flyout = (state: StateProps, action: IActions) => {
    injectSaga(NAMESPACE, flyoutSaga);
    injectReducer(NAMESPACE, flyoutReducer);

    const app = document.getElementsByClassName(FLYOUT_CLASSNAME)[0] as HTMLElement;
    const flyout = mountFlyout(app, action);

    mountAllOptionsPage(document.getElementById(ID_DYNAMIC_BUTTON_LIST), Array.from(flyout.panels), (event, id) => {
        action.setActivePage(id, isKeyboardTriggeredClickEvent(event));
    });

    // Get contact information from window or wait for event from simplicity
    const contactInfo = getContactInfoFromWindow();

    if (contactInfo) {
        action.setContactInfo(contactInfo);
    } else {
        window.addEventListener('flyout:contactInfoSet', (e: CustomEvent<SimplicityFlyoutConfigItem['content']>) => {
            action.setContactInfo(e.detail);
        });
    }

    // Listen to global navigation contact section for opening
    beacon.subscribe('sails:flyout', (msg: BeaconAction<BeaconSetActivePage>) => {
        switch (msg.type) {
            case 'TOGGLE_FLYOUT':
                action.setActivePage(msg.payload.activePage, msg.payload.focus, true);
                break;
        }
    });

    return {
        getDerivedStateFromProps (newState: StateProps, oldState: StateProps) {
            if (newState.isInitialized !== oldState.isInitialized && newState.isInitialized) {
                flyout.addDynamicContent(newState.simplicityContactInfo);
                flyout.reveal();
            }

            if (newState.activePage !== oldState.activePage) {
                newState.activePage && flyout.openPanel(newState.activePage, newState.focus, newState.external);
            }
        },
    };
};

/** State Mapping */
const mapStateToProps = createStructuredSelector<RootStateOrAny, StateProps>({
    activePage: selectActivePage(),
    simplicityContactInfo: selectSimplicityContactInfo(),
    isInitialized: selectIsInitialized(),
    focus: selectFocus(),
    external: selectExternal(),
});
const mapDispatch: IActions = {
    setContactInfo,
    setActivePage,
    addPage,
};

const Flyout = connect(mapStateToProps, mapDispatch)(flyout);

export default Flyout;
