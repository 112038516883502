import { createSelector } from 'reselect';
import { NAMESPACE } from 'Constant';
import { initialState } from './reducer';
import { IFlyoutState } from 'Container/Flyout/interface';

const selectState = (state): IFlyoutState => state[NAMESPACE] || initialState;
/**
 * selectPageActive
 */
export const selectSimplicityContactInfo = () =>
    createSelector(selectState, (state: IFlyoutState) => state.simplicityContactInfo);

/**
 * selectPageActive
 */
export const selectActivePage = () =>
    createSelector(selectState, (state: IFlyoutState) => state.activePage);

/**
 * selectAllPages
 */
export const selectAllPages = () =>
    createSelector(selectState, (state: IFlyoutState) => state.allPages);

/**
 * selectPreviousPage
 */
export const selectPreviousPage = () =>
    createSelector(selectState, (state: IFlyoutState) => state.previousPage);

/**
 * Flyout is initialized
 */
export const selectIsInitialized = () =>
    createSelector(selectState, (state: IFlyoutState) => !!state.simplicityContactInfo);

/**
 * Whether the flyout was opened via keyboard
 */
export const selectFocus = () =>
    createSelector(selectState, (state: IFlyoutState) => state.focus);

/**
 * Whether the flyout was opened via external action
 */
export const selectExternal = () =>
    createSelector(selectState, (state: IFlyoutState) => state.external);
